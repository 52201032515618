export class LetterGenerator {
  private vowelLabel : string[];
  private consonantLabel: string[];
  private vowels: number[][];
  private consonants : number[][];

  constructor() {
    this.vowelLabel = ['A', 'E', 'I', 'O', 'U'];
    this.consonantLabel = ['B', 'C', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'V', 'W', 'X', 'Y', 'Z' ];
    this.vowels = [
      [16961, 43502, 62735, 76766, 84042],
      [17668, 44535, 63092, 77032, 84965],
      [12738, 32125, 44653, 54000, 60061],
      [7489, 18853, 24822, 30289, 33802],
      [63982, 160617, 245793, 306544, 333060]
    ];
    this.consonants = [
      [4294, 13156, 21726, 24677, 31167, 36313, 36662, 38837, 50705, 56905, 71635, 77798, 78192, 94598, 116566, 131025, 133201, 135209, 135853, 138875, 139815],
      [4892, 13550, 22575, 25823, 32515, 37851, 38242, 40992, 53889, 60165, 74209, 80585, 81006, 97654, 118899, 132754, 134987, 137372, 138036, 141528, 142395],
      [3656, 9443, 16200, 18625, 23805, 27630, 28050, 30233, 39212, 43759, 53375, 58161, 58461, 70436, 85401, 94576, 96011, 97873, 98378, 100992, 101702],
      [2175, 5308, 9471, 10882, 13446, 15765, 16062, 17435, 22464, 25148, 30027, 32742, 32904, 39601, 47917, 52782, 53699, 54832, 55156, 57124, 57590],
      [13819, 51556, 76177, 85477, 106975, 126842, 127882, 132710, 177364, 202186, 266436, 292537, 293795, 353130, 436089, 498700, 507302, 511603, 514076, 528617, 533076]
    ];
  }

  GenerateNextFairVowelForGameLevel(vowelsSoFar: string, gameLevel: number) {
    // after 3 non-E letters generate an 'E'
    const letterEGenerated = vowelsSoFar.includes('E');
    if (vowelsSoFar.length == 3 && !letterEGenerated)
    {
        return 'E';
    }
    return this.GenerateNextVowel(gameLevel);
  }

  private GenerateNextVowel(gameLevel: number) {
    const number = (Math.random() * this.vowels[gameLevel][4]);
    for (let i = 0; i< 5; i++)
    {
        if (number <= this.vowels[gameLevel][i])
        {
            return this.vowelLabel[i];
        }
    }
    return 'E'; // should never get here!
  }

  GenerateNextConsonantForGameLevel(gameLevel:number)
  {
      const number = (Math.random() * this.consonants[gameLevel][20]);
      for (let i = 0; i< 21; i++)
      {
          if (number <= this.consonants[gameLevel][i])
          {
              return this.consonantLabel[i];
          }
      }
      return 'N'; // should never get here!
  }
}