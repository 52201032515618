import { Queue } from '@/trie/queue';


export class TrieNode {
  public static NumberOfNodes = 0;

  public letter: string;
  public children: object = new Map();
  public depth = 0;
  public isFinal = false;

  constructor(letter: string, depth: number) {
    this.letter = letter;
    this.depth = depth;
    TrieNode.NumberOfNodes++;
  }
}

export class SearchNode {
  public index: number;
  public position: number;

  constructor(index: number, position: number) {
    this.index = index;
    this.position = position;
  }
}

export class Trie {
  public TreeRoot!: TrieNode;
  public maxDepth = 0;
  public levelQueues!: Queue<TrieNode>[];

  private trieByteDataView!: Uint8Array;
  private trieCharDataView!: Uint8Array;
  private numberOfBits = 0;
  private bufferLength = 0;
  private numberOfNodes = 0;
  private selectCache!: number[];
  private BucketSize = 1000;

  public Root!: SearchNode;

  private isCheck = false;

  constructor() {
    this.Root = new SearchNode(0, 2);
    this.TreeRoot = new TrieNode(' ', -1);
    this.maxDepth = -1;

    const content = require('arraybuffer-loader!./50k.trie');
    this.Load(content);
    if (content instanceof ArrayBuffer) {
      this.Load(content)
    } else {
      this.Load(content.buffer);
    }
  }

  // -------------------------------------------------------------------------------------------
  // Properties (for tests)
  // -------------------------------------------------------------------------------------------
  public get NumberOfNodes(): number { return this.numberOfNodes; }
  public get NumberOfBits(): number { return this.numberOfBits; }
  public get BufferLength(): number { return this.bufferLength; }

  // -------------------------------------------------------------------------------------------
  // Public methods
  // -------------------------------------------------------------------------------------------
  public Load(buffer: ArrayBuffer) {
    let offset = 0;
    const int32View = new Int32Array(buffer, 0, 12); // reader.ReadInt32();
    this.numberOfNodes = int32View[0];
    this.numberOfBits = int32View[1];
    this.bufferLength = int32View[2];
    offset += 12;

    this.trieByteDataView = new Uint8Array(buffer, offset, this.bufferLength);
    offset += this.bufferLength;
    this.trieCharDataView = new Uint8Array(buffer, offset, this.numberOfNodes);

    this.selectCache = [];
    let yCount = 0;
    for (let i = 0; i < this.numberOfBits; i++) {
      yCount += (this.CheckBit(this.trieByteDataView, i)) ? 0 : 1;
      if ((yCount % this.BucketSize) == 0) {
        this.selectCache[yCount / this.BucketSize] = i;
      }
    }
    console.log(`Trie loaded ${this.numberOfNodes} nodes`);
  }

  public ContainsWord(word: string): boolean {
    this.isCheck = false;
    return this.ParseWord(this.Root, word, 0);
  }

  public GetWordCountsForLetters(allLetters: string): Map<number, string[]> {
    const words: string [] = [];
    const letters = allLetters.split('');
    this.GetWordsFromLetterArray(this.Root, '', letters, allLetters.length, words);
    const uniqueWords = [... new Set(words)];
    const grouped =  new Map();
    [...Array(9).keys()].forEach((n) => { grouped.set(n, []);})
    uniqueWords.forEach((w) => {
      const len = w.length;
      grouped.get(len).push(w);
    });    
    return grouped;
  }

  public GetWordsForLetters(allLetters: string): string[] {
    const words: string [] = [];
    const letters = allLetters.split('');
    this.GetWordsFromLetterArray(this.Root, '', letters, allLetters.length, words);
    const uniqueWords = [... new Set(words)];
    return uniqueWords;
  }

  // -------------------------------------------------------------------------------------------
  // Private methods
  // -------------------------------------------------------------------------------------------
  private CheckBit(data: Uint8Array, pos: number): boolean {
    const mask = [0x80, 0x40, 0x20, 0x10, 0x08, 0x04, 0x02, 0x01];
    const bytePos = Math.floor(pos / 8);
    const bitPos = pos % 8;
    return ((data[bytePos] & mask[bitPos]) > 0);
  }

  private ParseWord(node: SearchNode, word: string, index: number): boolean {
    if (word.length == index) return this.GetIsFinal(node);
    else {
      const numberOfChildren = this.GetNumberOfChildren(node);
      const childCandidate = this.GetFirstChild(node);
      if (childCandidate != undefined) {
        let child = childCandidate;
        for (let count = 0; count < numberOfChildren; count++) {
          if (this.GetCharacter(child) == word[index]) {
            return this.ParseWord(child, word, index + 1);
          }
          if (count < numberOfChildren - 1) child = this.GetNextSibling(child);
        }
      }
      return false;
    }
  }

  private GetIsFinal(node: SearchNode): boolean {
    if (node.index < 0 || node.index >= this.trieCharDataView.length) return true;
    return (this.trieCharDataView[node.index] & 0x80) > 0;
  }

  private GetNumberOfChildren(node: SearchNode): number {
    let count = 0;
    for (let i = node.position; this.CheckBit(this.trieByteDataView, i); i++) count++;
    return count;
  }

  private GetFirstChild(node: SearchNode): SearchNode | undefined {
    if (this.CheckBit(this.trieByteDataView, node.position) == false) return undefined;
    const index = this.Select0WithCache(node.index + 1) - node.index;
    const pos = this.Select0WithCache(index + 1) + 1;
    return new SearchNode(index, pos);
  }

  private GetNextSibling(node: SearchNode): SearchNode {
    let pos = node.position;
    while (this.CheckBit(this.trieByteDataView, pos) == true) pos++;
    return new SearchNode(node.index + 1, pos + 1);
  }

  private GetCharacter(node: SearchNode): string {
    if (node.index < 0 || node.index >= this.trieCharDataView.length) return '?';
    return String.fromCharCode((this.trieCharDataView[node.index] & 0x7F) + 97);
  }

  // returns position of the y-th 0
  private Select0WithCache(y: number): number {
    const bucket = Math.floor(y / this.BucketSize);
    let yCount = this.BucketSize * bucket;
    let start = this.selectCache[bucket] + 1;
    if (yCount == y) {
      yCount--;
      start = this.Select0WithCache(yCount) + 1;
    }
    for (let i = start; i < this.numberOfBits; i++) {
      yCount += (this.CheckBit(this.trieByteDataView, i)) ? 0 : 1;
      if (yCount == y) return i;
    }
    return 0;
  }

  private swapLetters(letters: string[], length: number, i: number): any
  {
      const l = letters[length - 1];
      letters[length - 1] = letters[i];
      letters[i] = l;
  }


  private GetWordsFromLetterArray(node: SearchNode, prefix: string, letters: string[], length: number, words: string [])  {
      if (this.GetIsFinal(node)) {
          words.push(prefix);
      }

      const numberOfChildren = this.GetNumberOfChildren(node);
      const childCandidate = this.GetFirstChild(node);
      if (childCandidate != undefined) {
        let child = childCandidate;
        for (let count = 0; count < numberOfChildren; count++) {
            const letter = this.GetCharacter(child);
            for (let i = 0; i < length; i++)
            {
                if (letters[i] == letter)
                {
                    this.swapLetters(letters, length, i);
                    this.GetWordsFromLetterArray(child, prefix + letter, letters, length - 1, words);
                    this.swapLetters(letters, length, i);
                }
            }
            if (count < numberOfChildren - 1) child = this.GetNextSibling(child);
        }
      }
  }

}
