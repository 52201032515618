<template>
  <div class="container" id="board-container">
    <div class="title">Letters with Friends</div>
    <div class="middle">
      <div class="middle-container" id="possible-words-container">
        <ion-list id="possibleWordsList">
          <ion-item v-for="word in possibleWords" :key="word">
            <ion-label>{{ word }}</ion-label>
          </ion-item>
        </ion-list>
      </div>
      <div class="middle-container" id="candidates-container">
        <ion-list id="candidatesList" class="mh-100">
          <ion-item v-for="word in candidateWords" :key="word">
            <div class="candidate-word">
              <div class="word-check"></div>
            <ion-label>{{ word }}</ion-label>
            </div>
          </ion-item>
        </ion-list>
      </div>
    </div>
    <div id="timer-bar-container">
      <div id="timer-bar"></div>
    </div>
    <div class="bottom">
      <div class="game-start-container" id="game-start-container">
        <ion-label id="gameTimeLabel">You have {{ gameSeconds }} seconds to find words</ion-label>
        <ion-label id="gameStartLabel">Game will start in {{ startSecondsRemaining }} seconds</ion-label>
      </div>

      <div class="three-buttons" id="game-buttons">
        <ion-button color="warning" expand="block" v-on:click="add_clicked"> Add </ion-button>
        <ion-button color="warning" expand="block" v-on:click="clear_rack_clicked"> Clear Rack </ion-button>
        <ion-button color="warning" expand="block" v-on:click="new_game_clicked">
          New Game
        </ion-button>
      </div>
      <div class="play-rack" id="target-rack">
        <play-rack />
      </div>
      <div class="play-rack" id="source-rack">
        <play-rack />
      </div>
      <div class="two-buttons" id="vc-buttons">
        <ion-button color="warning" expand="block" v-on:click="vowel_clicked">
          Vowel
        </ion-button>
        <ion-button color="warning" expand="block" v-on:click="consonant_clicked">
          Consonant
        </ion-button>
      </div>

      <div id="best-words-container">
        <ion-list class="mh-100">
          <ion-item v-for="word in bestWords" :key="word">
            <ion-label v-on:click="display_definition(word)">{{ word }}</ion-label>
          </ion-item>
        </ion-list>
      </div>
      <div class="three-buttons" id="new-game-button">
        <div></div>
        <ion-button color="warning" expand="block" v-on:click="new_game_clicked">
            New Game
        </ion-button>
        <div></div>
      </div>
    </div>
    <div id="copyright-container">
      &copy; Mercersoft 2023
    </div>
  </div>
</template>

<script>
import PlayRack from "@/components/PlayRack.vue";
import { LetterGenerator } from "@/trie/letter-generator";
import { Trie } from "@/trie/trie";
import {
  IonList,
  IonItem,
  IonLabel,
  IonButton,
} from "@ionic/vue";

export default {
  components: {
    PlayRack,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
  },
  name: "play-board",
  data: () => ({
    nextOpenSlot: 0,
    letters: [],
    sourceRack: [],
    targetRack: [],
    possibleWords: [],
    candidateWords: [],
    bestWords: [],
    startSecondsRemaining: 5,
    gameSeconds: 10,
    gameSecondsRemaining: 0,
    startTimer: undefined,
    gameTimer: undefined,
    letterGenerator: new LetterGenerator(),
    vowels: "",
    trie: new Trie(),

    tile: undefined,
    dragTile: undefined,
    dragActive: false,
    sourceRect: undefined,
    targetRect: unescape,
    currentX: 0,
    currentY: 0,
    initialX: 0,
    initialY: 0,
    midlineY: 0,
    isMove: false,
  }),

  methods: {
    display_definition: function(word) {
      const httpRequest = new XMLHttpRequest();
      const url = `https://api.dictionaryapi.dev/api/v2/entries/en/${word}`;
      httpRequest.open("GET", url);
      httpRequest.onreadystatechange = () => {
        if (httpRequest.readyState == 4 && httpRequest.status == 200) {
          console.log(httpRequest.responseText);
        }
      }
      httpRequest.send();
    },

    vowel_clicked: function () {
      let letter = new LetterGenerator().GenerateNextFairVowelForGameLevel(
        this.vowels,
        0
      );
      this.vowels += letter;
      this.addTileToSource(letter, this.nextOpenSlot);
    },
    consonant_clicked: function () {
      let letter = new LetterGenerator().GenerateNextConsonantForGameLevel(0);
      this.addTileToSource(letter, this.nextOpenSlot);
    },

    add_clicked: function () {
      this.addWordToCandidates();
    },

    clear_rack_clicked: function () {
      this.resetBoard();
    },

    new_game_clicked: function () {
      this.resetToSetup();
    },

    /* private methods */

    addTileToSource: function (letter, index) {
      this.sourceRack.push(letter);
      const tile = this.createTile(letter, index, "source-rack");

      const slot = document.querySelector(`#source-rack #slot_${index}`);
      slot?.appendChild(tile);
      this.nextOpenSlot += 1;
      if (this.nextOpenSlot == 8) {
        this.setupToReview();
      }
    },

    createTile: function (letter, index, rackName) {
      var tile = document.createElement("div");
      tile.setAttribute("class", "tile");
      tile.setAttribute("data-letter", letter);
      tile.setAttribute("data-index", index);
      tile.setAttribute("data-rack", rackName);
      return tile;
    },

    setupToReview: function () {
      setTimeout(this.updatePossibleWords, 200);
    },

    reviewToPlay: function () {
      let possibleWordsContainer = document.getElementById(
        "possible-words-container"
      );
      let targetRack = document.getElementById("target-rack");
      let gameStartContainer = document.getElementById("game-start-container");
      let gameButtons = document.getElementById("game-buttons");
      let candidateWordsContainer = document.getElementById("candidates-container");

      /* hide */
      possibleWordsContainer.style.display = "none";
      gameStartContainer.style.display = "none";

      /* show */
      targetRack.style.display = "block";
      gameButtons.style.display = "grid";
      candidateWordsContainer.style.display = "block";

      this.initializeEvents();
      this.sourceRect = document.getElementById("source-rack").getBoundingClientRect();
      this.targetRect = document.getElementById("target-rack").getBoundingClientRect();

      // initialize here so I can initialize the value only once
      // 10 times because we update each 1/10 seconds
      this.gameSecondsRemaining = 10 * this.gameSeconds;  
      this.gameTimer = setInterval(this.decrementGameTimer, 100);
    },

    initializeEvents: function () {
      const boardContainer = document.getElementById("board-container");
      boardContainer.addEventListener("pointerdown", this.dragStart, false);
      boardContainer.addEventListener("pointerup", this.dragEnd, false);
      boardContainer.addEventListener("pointermove", this.dragMove, false);
    },

    removeEvents: function () {
      const boardContainer = document.getElementById("board-container");
      boardContainer.removeEventListener("pointerdown", this.dragStart);
      boardContainer.removeEventListener("pointerup", this.dragEnd);
      boardContainer.removeEventListener("pointermove", this.dragMove);
    },

    /* creats a new tile as the child of the container in the same location as 'tile' */
    createDragTile: function (tile) {
      const boardContainer = document.getElementById("board-container");
      //const boardContainerRect = boardContainer.getBoundingClientRect();
      const letter = tile.attributes["data-letter"].value;
      const index = tile.attributes["data-index"].value;
      const rack = tile.attributes["data-rack"].value;
      const tileRect = tile.getBoundingClientRect();
      const newTile = document.createElement("div");
      newTile.setAttribute("class", "tile dragtile");
      newTile.setAttribute("data-letter", letter.toLowerCase());
      newTile.setAttribute("data-index", index);
      newTile.setAttribute("data-rack", rack);
      newTile.style.position = "absolute";

      newTile.style.left = `${tileRect.left}px`;
      newTile.style.top = `${tileRect.top}px`;
      newTile.style.width = tile.style.width;
      newTile.style.height = tile.style.height;

      boardContainer.appendChild(newTile);
      return newTile;
    },

    resetBoard: function () {
      const sourceRackSlots = document.getElementById('source-rack').getElementsByClassName('slot');
      const targetRackSlots = document.getElementById('target-rack').getElementsByClassName('slot');
      var i = 0, lastTargetIndex = 0;
      while (targetRackSlots[i] != undefined) {
        if (targetRackSlots[i].childElementCount > 0) {
          const targetSlotIndex = this.getNextOpenSourceRackSlotIndex(sourceRackSlots, lastTargetIndex);
          const tile = targetRackSlots[i].children[0];
          const tileLetter = tile.attributes["data-letter"].value;
          const newTile = this.createTile(
            tileLetter,
            targetSlotIndex,
            "source-rack"
          );

          tile.remove();
          sourceRackSlots[targetSlotIndex].appendChild(newTile);
          lastTargetIndex = targetSlotIndex;
        }
        i++;
      }
    },

    getNextOpenSourceRackSlotIndex: function (slots, index) {
      while (slots[index].childElementCount === 1) {
        index++;
      }
      return index;
    },

    dragStart: function (e) {
      if (!e.target.className.includes("button")) {
        // if (e.touches === undefined) {
        //   console.log(
        //     `dragStart for ${e.target.className} at ${e.clientX} ${e.clientY}`
        //   );
        // } else {
        //   console.log(
        //     `dragStart [touches] for ${e.target.className} at ${e.touches[0].clientX} ${e.touches[0].clientY}`
        //   );
        // }
        this.tile = e.target;
        if (e.target.className == "tile") {
          this.dragTile = this.createDragTile(this.tile);
          this.tile.remove();

          if (e.type === "touchstart") {
            this.initialX = e.touches[0].clientX;
            this.initialY = e.touches[0].clientY;
          } else {
            this.initialX = e.clientX;
            this.initialY = e.clientY;
          }

          if (e.target === this.tile) {
            this.active = true;
          }
        }
        this.isMove = true;
      }
    },

    dragMove: function (e) {
      // if (e.touches === undefined) {
      //     console.log(
      //       `dragMove for ${e.target.className} at ${e.clientX} ${e.clientY}`
      //     );
      //   } else {
      //     console.log(
      //       `dragMove [touches] for ${e.target.className} at ${e.touches[0].clientX} ${e.touches[0].clientY}`
      //     );
      //   }
      if (this.active) {
        e.preventDefault();
        this.isMove = true;
        if (e.type === "touchmove") {
          this.currentX = e.touches[0].clientX - this.initialX;
          this.currentY = e.touches[0].clientY - this.initialY;
        } else {
          this.currentX = e.clientX - this.initialX;
          this.currentY = e.clientY - this.initialY;
        }

        this.xOffset = this.currentX;
        this.yOffset = this.currentY;

        this.setTranslate(this.currentX, this.currentY, this.dragTile);
      }
    },


    dragEnd: function () {
      // if (e.touches === undefined) {
      //     console.log(
      //       `dragEnd for ${e.target.className} at ${e.clientX} ${e.clientY}`
      //     );
      //   } else {
      //     console.log(
      //       `dragEnd [touches] for ${e.target.className} at ${e.touches[0].clientX} ${e.touches[0].clientY}`
      //     );
      //   }
      if (this.isMove) {
        this.initialX = this.currentX;
        this.initialY = this.currentY;

        this.active = false;

        const dragTileRect = this.dragTile.getBoundingClientRect();
        const rack = this.dragTile.attributes["data-rack"].value;
        this.processDragEnd(
          rack,
          rack === "source-rack" ? "target-rack" : "source-rack",
          this.dragTile,
          dragTileRect
        );
      }
      this.isMove = false;
    },

    processDragEnd: function (originRack, destinationRack, tile, tileRect) {
      // console.log(`processDragEnd: origin: ${originRack} dest: ${destinationRack}`);
      const slot0Rect = document
        .querySelector(`#${destinationRack} #slot_0`)
        .getBoundingClientRect();
      const toSlotIndex = Math.trunc(
        (tileRect.x + tileRect.width / 2 - slot0Rect.x) / tileRect.width
      );
      const tileLetter = tile.attributes["data-letter"].value;
      const sourceIndex = Number(tile.attributes["data-index"].value);

      if (toSlotIndex > -1) {
        const newTile = this.createTile(
          tileLetter,
          toSlotIndex,
          destinationRack
        );
        const slot = document.querySelector(
          `#${destinationRack} #slot_${toSlotIndex}`
        );

        tile.remove();
        slot.appendChild(newTile);
      } else {
        const slot = document.querySelector(
          `#${originRack} #slot_${sourceIndex}`
        );
        const newTile = this.createTile(
          tileLetter,
          sourceIndex,
          document.querySelector(`#${originRack}`)
        );

        tile.remove();
        slot.appendChild(newTile);
      }
    },

    setTranslate: function (xPos, yPos, el) {
      el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
    },

    hitTest: function (rectA, rectB) {
      var rA = rectA.x + rectA.width; // Right side of rectA
      var rB = rectB.x + rectB.width; // Right side of rectB
      var bA = rectA.y + rectA.height; // Bottom of rectA
      var bB = rectB.y + rectB.height; // Bottom of rectB

      var hitX = rA > rectB.x && rectA.x < rB; // True if hit on x-axis
      var hitY = bA > rectB.y && rectA.y < bB; // True if hit on y-axis

      // Return true if hit on x and y axis
      return hitX && hitY;
    },

    resetToSetup: function () {
      const vcButtons = document.getElementById("vc-buttons");
      const possibleWordsContainer = document.getElementById("possible-words-container");
      const targetRack = document.getElementById("target-rack");
      const gameButtons = document.getElementById("game-buttons");
      const candidateWordsContainer = document.getElementById("candidates-container");
      const bestWordsList = document.getElementById("best-words-container");
      const newGameButton = document.getElementById('new-game-button');

      /* show */
      vcButtons.style.display = "grid";

      /* hide */
      possibleWordsContainer.style.display = "none";
      targetRack.style.display = "none";
      gameButtons.style.display = "none";
      candidateWordsContainer.style.display = "none";
      bestWordsList.style.display = "none";
      newGameButton.style.display = "none";

      /* clear */
      const sourceTiles = document.querySelectorAll(`#source-rack .tile`);
      sourceTiles.forEach((t) => t.remove());
      const targetTiles = document.querySelectorAll(`#target-rack .tile`);
      targetTiles.forEach((t) => t.remove());
      this.nextOpenSlot = 0;
      this.sourceRack = [];
      this.targetRack = [];
      this.possibleWords = [];
      this.candidateWords = [];
      this.startSecondsRemaining = 5;
      this.vowels = "";
      this.startTimer = undefined;
      this.removeEvents();
    },

    addWordToCandidates: function () {
      const letters = [];
      const targetRackSlots = document.getElementById('target-rack').getElementsByClassName('slot');
      var i = 0;
      while (targetRackSlots[i] != undefined) {
        if (targetRackSlots[i].childElementCount > 0) {
          const tile = targetRackSlots[i].children[0];
          const tileLetter = tile.attributes["data-letter"].value;
          letters.push(tileLetter);
        }
        i++;
      }

      const word = letters.join('');
      console.log(`word is ${word}`);
      this.candidateWords.push(word);
      this.resetBoard();
    },

    updatePossibleWords: function () {
      this.letters = this.sourceRack.join("");
      const result = this.trie.GetWordCountsForLetters(this.letters.toLowerCase());
      let counts = [];
      for (let i = 2; i < 9; i++) {
        const part = result.get(i);
        if (part != undefined) {
          const count = part.length;
          if (count > 0) {
            counts.push(`${count} words of length ${i}`);
          }
        } else {
          console.log(`result.get(${i}) returned undefined`);
        }
      }
      this.possibleWords = counts;

      /* hide */
      let vcButtons = document.getElementById("vc-buttons");
      vcButtons.style.display = "none";

      /* show */
      let gameStartContainer = document.getElementById("game-start-container");
      let possibleWordsContainer = document.getElementById("possible-words-container");
      let timerBar = document.getElementById("timer-bar");

      gameStartContainer.style.display = "grid";
      possibleWordsContainer.style.display = "block";
      timerBar.style.display = "block";
      this.startTimer = setInterval(this.decrementStartTimer, 1000);
    },

    decrementStartTimer: function () {
      this.startSecondsRemaining -= 1;
      if (this.startSecondsRemaining == 0) {
        clearInterval(this.startTimer);
        this.reviewToPlay();
      }
    },

    decrementGameTimer: function () {
      this.gameSecondsRemaining -= 1;
      if (this.gameSecondsRemaining < 0) {
        clearInterval(this.gameTimer);
        this.playToGameEnd();
      } else {
        let timerBar = document.getElementById("timer-bar");
        let width = `${10 *(this.gameSecondsRemaining / this.gameSeconds)}%`;
        timerBar.style.width = width; 
      }
    },

    playToGameEnd: function () {
      const words = this.trie.GetWordsForLetters(this.letters.toLowerCase());
      this.bestWords = words.sort(function(a,b) { return b.length - a.length;}).slice(0, Math.min(words.length,3));
      this.scoreCandidates(words);

      const gameButtons = document.getElementById('game-buttons');
      const targetRack = document.getElementById('target-rack');
      const bestWordsContainer = document.getElementById('best-words-container');
      const newGameButton = document.getElementById('new-game-button');

      /* hide */
      gameButtons.style.display = "none";
      targetRack.style.display = "none";

      /* show */
      bestWordsContainer.style.display = "grid";
      newGameButton.style.display = "grid";
    },

    scoreCandidates: function (words) {
      let candidateWordDivs = document.getElementsByClassName("candidate-word");
      for (let d of candidateWordDivs) {
        let circle = d.children[0];
        let wordDiv = d.children[1];
        let word = wordDiv.textContent;
        if (words.includes(word)) {
          circle.classList.add("green-circle");
        } else {
          circle.classList.add("red-circle");
        }
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 5fr 5px 4fr 32px;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "."
    "."
    ".";
  height: 100%;
  margin: 0;
}

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150%;
}

.middle {
  background-color: #111;
}

.bottom {
  display: grid;
  margin-top: 2em;
  touch-action: none;
}

.two-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
  margin: 2em;
}

.three-buttons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  margin-left: 2em;
  margin-right: 2em;
}

.play-rack {
  margin-top: 1em;
}

.game-start-container {
  display: none;
  margin: 2em;
}

.middle-container {
  margin: 4em;
}

#board-container {
  margin: 0;
}

#game-buttons {
  display: none;
}

#new-game-button {
  display: none;
}

#game-start-container {
  display: none;
  grid-gap: 1em;
  text-align: center;
}

#timer-bar-container {
  width: 100%;
  height: 0.2em;
}

#timer-bar {
  display: none;
  background-color: gold;
  width: 100%;
  height: 100%;
}

#candidates-container {
  display: none;
}

.word-check {
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 1em;
}

.candidate-word {
  display: grid;
  grid-template-columns: 1em 1fr;
  grid-gap: 1em;
}

.red-circle {
  background-color: red;
}

.green-circle {
  background-color: greenyellow;
}

#target-rack {
  display: none;
}

#possibleWordsContainer {
  display: block;
  padding: 0;
}

#possibleWordsList {
  display: block;
  padding: 0;
}

.dragTile {
  background-color: aqua;
  z-index: 1000;
}

#best-words-container {
  margin-left: 4em;
  margin-right: 4em;
}

#copyright-container {
  text-align: center;
}
</style>
